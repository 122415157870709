import React from 'react';
import './WebDesign.css';
import {WebDesignData} from '../../component/Data/Data'

import { motion } from 'framer-motion'
import Card from '../../component/card/Card'

const containerVariants = {
  hidden: {
    opacity: 0,
    x: '100vw'
  },

  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      delay: 1,
      duration: 0.5
    }
  },
  exit: {
    x: '-100vw',
    transition: { ease: 'easeInOut' }
  }
}

const WebDesign = () => {

    const designComp = WebDesignData.map((data) => {
      return (
        <Card
          key={data.id}
          title={data.title}
          src={data.src}
          desc={data.desc}
        />
      );
    });

  return (
    <>
      <motion.div variants={containerVariants} animate='visible' exit='exit'>
        <h1 className='webDesign'>Web Design</h1>
        {designComp}
      </motion.div>
    </>
  )
}

export default WebDesign

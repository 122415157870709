import React, {useState} from 'react'
import { Card, CardMedia, Featured, StatusRibbon , FeatureTitle, Status, CardBody, CardTitle, MetaInfo, Developer, Avatar, Info, PriceInfo, Price, PriceText, TagsCa, Tags, CtaGarp, CtaLink, MetaList, MetaListItem, CardLink} from './ProjectCardStyle'
import './ProjectCard.css';
import {motion} from 'framer-motion';
// import { Link } from 'react-router-dom';


const ProjectCard = ({data}) => {
  const [isHovered , setIsHovered] = useState(false);

  const animateCardMedia = isHovered ? {height: "170px"} : {height: "auto"};
  const showMeta = {opacity: 1, height: "auto" };
  const hideMeta = { opacity: 0, height: 0};

  const animateMeta = isHovered ? hideMeta : showMeta;

  const animateMetaList = isHovered ? showMeta : hideMeta;

  const transition = { 
    duration: 0.25,
    type: "spring",
    bounce: 0.2,
    ease: "easeIn"
}

  return (
    <motion.div
      className="Card"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      key={data?.id}
    >
      <CardMedia animate={animateCardMedia} transition={transition}>
        <video controls alt="project-Logo">
          <source src={data?.projectVideo} type="video/mp4" />
        </video>
        {data?.fundStatus === 2 && (
          <Featured>
            <FeatureTitle>Ended !</FeatureTitle>
          </Featured>
        )}
        {/* <StatusRibbon fundStatus={data?.fundStatus}>
          <Status>
            {data?.fundStatus === 1 ? "Funding is Alive" : "Funding Ended"}
          </Status>
        </StatusRibbon> */}
      </CardMedia>

      <CardBody>
        <CardTitle>
            <h5>{data?.projectTitle}</h5>
          <h5>{data?.projectAction}</h5>
        </CardTitle>
        {isHovered && (
          <MetaInfo animate={animateMeta}>
            <Developer>
              <Avatar>
                <img src={data?.projectLogo} alt="" />
              </Avatar>
              {/* <Info>
                <span>Client</span>
                <h4>{data?.clientName}</h4>
              </Info> */}
            </Developer>
            {/* <PriceInfo>
            <span>Funds Required</span>
            <Price>
              <PriceText>{data?.fundsRequired}</PriceText>
            </Price>
          </PriceInfo> */}
          </MetaInfo>
        )}
        <TagsCa animate={animateMetaList}>
          <CardLink href={data?.link} target="_blank">
          <Tags>
            <span>Website Link</span>
          </Tags>
          </CardLink>
          <CtaGarp>
            <CtaLink href="/">View Now</CtaLink>
          </CtaGarp>
          
        </TagsCa>
        <MetaList animate={animateMetaList}>
          <MetaListItem>
            <div>Client</div>
            <div>{data?.clientName}</div>
          </MetaListItem>
          <MetaListItem>
            <div>UI</div>
            <div>{data?.metaList?.frontend}</div>
          </MetaListItem>
          <MetaListItem>
            <div>Backend</div>
            <div>{data?.metaList?.backend}</div>
          </MetaListItem>
          {/* <MetaListItem>
            <div>Soft Cap</div>
            <div>{data?.metaList?.soft_cap}</div>
          </MetaListItem> */}
          {/* <MetaListItem>
            <div>Employees</div>
            <div>{data?.metaList?.employee_count}</div>
          </MetaListItem> */}
        </MetaList>
      </CardBody>
    </motion.div>
  );
}

export default ProjectCard
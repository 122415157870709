import React from "react";
import { Link } from "react-router-dom";
import "./Services.css";

import webDev from "../../images/webdev.png";
import AppDev from "../../images/appdev.png";
import DeskTopApp from "../../images/desktdev.png";
import UXDes from "../../images/uxdes.png";
import GraphicBrand from "../../images/graphicdes.png";
import BrandingServ from "../../images/brandguid.png";
import WhyChooseUs from "../../component/WhyChooseUs/WhyChooseUs";
import Review from "../../component/Review/Review";
import { FormTitle } from "../../component/FormTitle/FomTitle";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: {
    opacity: 0,
    //  x: '100vw'
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      delay: 0.5,
    },
  },
  exit: {
    x: "-100vw",
    transition: { ease: "easeInOut" },
  },
};

const childVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 3,
      duration: 3,
    },
  },
};

function Services() {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="Services"
    >
      <div className="Services-Wrapper">
        <div className="Services-Content-Heading">
          <FormTitle className="sign-in-form__title" text="Services" />
          <p className="services-p">
            At Beautiful Responsive Web, we take pride in offering a
            comprehensive suite of web development services tailored to meet the
            unique needs of our clients. With a team of experienced and skilled
            professionals, we strive to deliver cutting-edge solutions that
            elevate your online presence and drive business growth. Our
            commitment to quality, innovation, and client satisfaction sets us
            apart in the competitive landscape of web development. Whether
            you're a startup, a growing business, or an established enterprise,
            our versatile range of web development services is designed to
            propel you to digital success. Partner with us and let's build a
            powerful online presence together.
          </p>
        </div>
        <div className="Services-Top-Content-Wrapper">
          <div className="Services-Top-Content">
            <div className="Services-Contents">
              <div className="Services-Top-Img">
                <div className="Services-Top-Img-Wrapper">
                  <img src={webDev} alt="" />
                </div>
              </div>
              <div className="Services-Bottom-Wrapper">
                <div className="Services-Bottom">
                  <h2>Web Development</h2>
                  <p>
                    Our team specializes in creating bespoke web solutions that
                    align with your business objectives. Whether you need a
                    corporate website, an e-commerce platform, or a complex web
                    application, we leverage the latest technologies and
                    industry best practices to deliver tailor-made solutions.
                  </p>

                  <motion.button
                    whileHover={{
                      scale: 1.1,
                      textShadow: "0px 0px 8px rgb(255,255,255,)",
                      boxShadow: "0px 0px 8px rgb(255,255,255,)",
                    }}
                    whileTap={{ scale: 0.9 }}
                    type="button"
                    variants={childVariants}
                    className="Services-Button"
                  >
                    <Link to="/contact">Get Started</Link>
                  </motion.button>
                </div>
              </div>
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              className="Services-Contents"
              style={{ backgroundColor: "#0FC0CF" }}
            >
              <div className="Services-Top-Img">
                <div className="Services-Top-Img-Wrapper">
                  <img src={AppDev} alt="" />
                </div>
              </div>
              <div className="Services-Bottom-Wrapper">
                <div className="Services-Bottom">
                  <h2>Responsive Design</h2>
                  <p>
                    In an era where users access websites on various devices, a
                    responsive design is crucial. We ensure that your web
                    presence is optimized for seamless performance across
                    desktops, tablets, and mobile devices, providing an
                    exceptional user experience.
                  </p>

                  <motion.button
                    whileHover={{
                      scale: 1.1,
                      textShadow: "0px 0px 8px rgb(255,255,255,)",
                      boxShadow: "0px 0px 8px rgb(255,255,255,)",
                    }}
                    whileTap={{ scale: 0.9 }}
                    type="button"
                    variants={childVariants}
                    className="Services-Button"
                  >
                    <Link to="/contact">Get Started</Link>
                  </motion.button>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              className="Services-Contents"
              style={{ backgroundColor: " #1CC191" }}
            >
              <div className="Services-Top-Img">
                <div className="Services-Top-Img-Wrapper">
                  <img src={DeskTopApp} alt="" />
                </div>
              </div>
              <div className="Services-Bottom-Wrapper">
                <div className="Services-Bottom">
                  <h2>E-Commerce Development</h2>
                  <p>
                    For businesses looking to establish or enhance their online
                    retail presence, we offer robust e-commerce development
                    services. From user-friendly interfaces to secure payment
                    gateways, we create e-commerce platforms that facilitate
                    smooth transactions and foster customer loyalty.
                  </p>

                  <motion.button
                    whileHover={{
                      scale: 1.1,
                      textShadow: "0px 0px 8px rgb(255,255,255,)",
                      boxShadow: "0px 0px 8px rgb(255,255,255,)",
                    }}
                    whileTap={{ scale: 0.9 }}
                    type="button"
                    variants={childVariants}
                    className="Services-Button"
                  >
                    <Link to="/contact">Get Started</Link>
                  </motion.button>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              className="Services-Contents"
              style={{ backgroundColor: " #512888" }}
            >
              <div className="Services-Top-Img">
                <div className="Services-Top-Img-Wrapper">
                  <img src={UXDes} alt="" />
                </div>
              </div>
              <div className="Services-Bottom-Wrapper">
                <div className="Services-Bottom">
                  <h2>Hosting and Deployment</h2>
                  <p>
                    Our services include comprehensive hosting and deployment
                    solutions. We guide you through the process of selecting the
                    right hosting environment and ensure a seamless deployment,
                    guaranteeing optimal performance and reliability.
                  </p>

                  <motion.button
                    whileHover={{
                      scale: 1.1,
                      textShadow: "0px 0px 8px rgb(255,255,255,)",
                      boxShadow: "0px 0px 8px rgb(255,255,255,)",
                    }}
                    whileTap={{ scale: 0.9 }}
                    type="button"
                    variants={childVariants}
                    className="Services-Button"
                  >
                    <Link to="/contact">Get Started</Link>
                  </motion.button>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              className="Services-Contents"
              style={{ backgroundColor: "#EF612B" }}
            >
              <div className="Services-Top-Img">
                <div className="Services-Top-Img-Wrapper">
                  <img src={GraphicBrand} alt="" />
                </div>
              </div>
              <div className="Services-Bottom-Wrapper">
                <div className="Services-Bottom">
                  <h2>Consultation and Strategy</h2>
                  <p>
                    Consultation and Strategy We understand that each project is
                    unique. Our experts provide personalized consultation and
                    strategic planning to align our services with your business
                    goals. We work collaboratively to define a roadmap for
                    success. At Beautiful Responsive Web, we are committed to
                    delivering excellence in every project.
                  </p>

                  <motion.button
                    whileHover={{
                      scale: 1.1,
                      textShadow: "0px 0px 8px rgb(255,255,255,)",
                      boxShadow: "0px 0px 8px rgb(255,255,255,)",
                    }}
                    whileTap={{ scale: 0.9 }}
                    type="button"
                    variants={childVariants}
                    className="Services-Button"
                  >
                    <Link to="/contact">Get Started</Link>
                  </motion.button>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              className="Services-Contents"
              style={{ backgroundColor: " #425AC1" }}
            >
              <div className="Services-Top-Img">
                <div className="Services-Top-Img-Wrapper">
                  <img src={BrandingServ} alt="" />
                </div>
              </div>
              <div className="Services-Bottom-Wrapper">
                <div className="Services-Bottom">
                  <h2>Branding Guides</h2>
                  <p>
                    Branding is a very important part of giving your business an
                    identity. hether its the color sheme, business cards or
                    specific feel, we can work closely with you in order to make
                    sure everything is to your liking.
                  </p>

                  <motion.button
                    whileHover={{
                      scale: 1.1,
                      textShadow: "0px 0px 8px rgb(255,255,255,)",
                      boxShadow: "0px 0px 8px rgb(255,255,255,)",
                    }}
                    whileTap={{ scale: 0.9 }}
                    type="button"
                    variants={childVariants}
                    className="Services-Button"
                  >
                    <Link to="/contact">Get Started</Link>
                  </motion.button>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
        <WhyChooseUs />
        <Review />
      </div>
    </motion.div>
  );
}

export default Services;

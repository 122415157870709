import React, { useState } from "react";
import axios from "axios";
import "./NewsLetter.css";
import NewsLogo from "../../images/LOGO.png";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const containerVariants = {
  hidden: {
    opacity: 0,
    x: '100vw'
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      delay: 0.5,
    },
  },
  exit: {
    x: "-100vw",
    transition: { ease: "easeInOut" },
  },
};

const NewsLetter = () => {
  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();

  const postNews = (e) => {
    e.preventDefault();
    axios
      .post("http://localhost:5005/newsletter", {
        fullName,
        email,
      })
      .then((res) => console.log("subscribing to Newsletter", res))
      .catch((err) => console.log(err));
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="News-Letter"
    >
      <div className="newsletter-wrapper">
        <div className="newsletter-content">
          <div className="newLogo">
            <img src={NewsLogo} alt="" />
          </div>
          <div className="Newletter-header">
            <h1>Newsletter SignUp</h1>
            <p>
              The best way to stay up to day on our current and upcoming event
            </p>
          </div>
          <form className="Newsletter-Form">
            <div className="Form-Inputs">
              <input
                className="Form-Input-input"
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Full Name ..."
              />
            </div>
            <div className="Form-Inputs">
              <input
                className="Form-Input-input"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email address ..."
              />
            </div>
            <div className="news-Btn">
              <Link type="/">
                <button>Submit</button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </motion.div>
  );
};

export default NewsLetter;

import React from "react";
import "./Design.css";
import FirstDesign from "../../images/1st desing logo.png";
import SecondDesign from "../../images/2nddesignlogo.png";
import ThirdDesign from "../../images/3rdDesign.png";
import ForthDesign from "../../images/4designkaneli.png";
import FifthDesign from "../../images/5designKc.png";
import { motion } from "framer-motion";

const imageVariants = {
  tap: {
    scale: 1.1,
    transition: {
      duration: 0.3,
    },
  },
  hover: {
    scale: 1.1,
    textShadow: "0px 0px 8px rgba(255, 255, 255, 0.7)",
    boxShadow: "0px 0px 8px rgba(255, 255, 255, 0.7)",
    transition: {
      duration: 0.3,
      yoyo: Infinity,
    },
  },
  initial: {
    x: "-250",
  },
  animate: {
    x: 0,
  },
};

const Designs = () => {
  return (
    <div className="Designs">
      <div className="Design-Wrapper">
        <p>Trusted by industry leaders and best-in-class brands</p>
        <div className="Design-Work">
          {[
            FirstDesign,
            SecondDesign,
            ThirdDesign,
            ForthDesign,
            FifthDesign,
          ].map((design, index) => (
            <motion.div
              key={index}
              className="Logo-Desing"
              variants={imageVariants}
              whileHover="hover"
              whileTap="tap"
              initial="initial"
              animate="animate"
            >
              <img src={design} alt={`Design ${index + 1}`} />
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Designs;

import React from 'react'
import './Seo.css'

import { motion } from 'framer-motion'

const containerVariants = {
  hidden: {
    opacity: 0,
    x: '100vw'
  },

  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      delay: 1,
      duration: 0.5
    }
  },
  exit: {
    x: '-100vw',
    transition: { ease: 'easeInOut' }
  }
}

const Seo = () => {
  return (
    <>
      <motion.div variants={containerVariants} animate='visible' exit='exit'>
        <h1>Search Engine Optimization </h1>
      </motion.div>
    </>
  )
}

export default Seo

import { motion } from 'framer-motion';
import React from 'react'
import './BusinessCardDesign.css'


const containerVariants = {
  hidden: {
    opacity: 0,
    x: "100vw",
  },

  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      delay: 1,
      duration: 0.5,
    },
  },
  exit: {
    x: "-100vw",
    transition: { ease: "easeInOut" },
  },
};

const BusinessCardDesign = () => {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      BusinessCardDesign
    </motion.div>
  );
}

export default BusinessCardDesign
import React from "react";
import "./Footer.css";
import "../Buttons/FooterActButton.css";
import "../Buttons/FooterNonActButton.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import IconBxPhoneCall from "./Footer-Svg-Icons/IconBxPhoneCall";
import IconEmailFastOutline from "./Footer-Svg-Icons/IconEmailFastOutline";
import IconBxLocationPlus from "./Footer-Svg-Icons/IconBxLocationPlus";
import IconSocialLinkedinCircular from "./Footer-Svg-Icons/IconSocialLinkedinCircular";
import IconSocialFacebookCircular from "./Footer-Svg-Icons/IconSocialFacebookCircular";
import IconInstagram from "./Footer-Svg-Icons/IconInstagram";
import IconSocialTwitterCircular from "./Footer-Svg-Icons/IconSocialTwitterCircular";
import IconYoutube from "./Footer-Svg-Icons/IconYoutube";

const buttonVariants = {
  hover: {
    scale: 1.1,
    textShadow: "0px 0px 8px rgb(255, 255, 255)",
    boxShadow: "0px 0px 8px rgb(255, 255, 255)",
    transition: {
      duration: 0.3,
      yoyo: Infinity,
    },
  },
};

const nextVariant = {
  hidden: {
    x: "-100",
  },
  visible: {
    x: 0,
    transition: {
      type: "spring",
      stiffness: 120,
    },
  },
};

const Footer = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      className="Footer"
    >
      <div className="Footer-Wrapper">
        <div className="Footer-header">
          <h1>Bringing Your Ideas to Life</h1>
          <p>Here are more ways to discover how we can help you.</p>
          <div className="Footer-Buttons">
            <Link to="/contact">
              <motion.button
                type="button"
                variants={buttonVariants}
                whileHover="hover"
                className="FooterActBtn"
              >
                Contact Us
              </motion.button>
            </Link>

            <Link to="/about">
              <motion.button
                type="button"
                variants={buttonVariants}
                whileHover="hover"
                className="FooterNonActBtn"
              >
                About us
              </motion.button>
            </Link>
          </div>
          <div className="Footer-Content-Wrapper">
            <div className="FooterContact-Content">
              <h3>Get in touch</h3>
              <Link to="">
                <div className="icon">
                  <IconBxPhoneCall />
                  <Link>
                    <p>+ 1 949-919-4017</p>
                  </Link>
                </div>
              </Link>

              <Link to="" target="_blank">
                <div className="icon">
                  <IconEmailFastOutline />
                  <p>beautifulresponsiveweb@gmail.com</p>
                </div>
              </Link>

              <Link to="">
                <div className="icon">
                  <IconBxLocationPlus /> <p>Orange County, California</p>
                </div>
              </Link>
            </div>
            <div className="Footer-Company">
              <h3>Company</h3>
              <ul className="Footer-Company-links">
                <Link to="/about">About Us </Link>

                <Link to="/faq">Faq</Link>

                <Link to="/contact">Contact </Link>
              </ul>
            </div>
            <div className="Footer-Resources">
              <h3>Resources</h3>
              <ul className="Footer-Resources-Links">
                <Link to="/projects">Projects</Link>

                <Link to="/features">Add On Features</Link>

                <Link to="/newsletter">Blog</Link>
              </ul>
            </div>
            <div className="Footer-Social-Media-Wrapper">
              <h3>Follow Us</h3>
              <div className="Social_Media-Wrapper">
                <Link
                  to="https://www.linkedin.com/company/beautiful-responsive-web/"
                  target="_blank"
                >
                  <div className="Social_Media_icon">
                    <IconSocialLinkedinCircular />
                  </div>
                </Link>

                <Link
                  to="https://www.facebook.com/BeautifulResponsiveWeb"
                  target="_blank"
                >
                  <div className="Social_Media_icon">
                    <IconSocialFacebookCircular />
                  </div>
                </Link>

                <Link
                  to="https://www.instagram.com/beautiful_responsive_web/"
                  target="_blank"
                >
                  <div className="Social_Media_icon">
                    <IconInstagram />
                  </div>
                </Link>
                <Link to="https://twitter.com/Beautifulresweb" target="_blank">
                  <div className="Social_Media_icon">
                    <IconSocialTwitterCircular />
                  </div>
                </Link>
                <Link
                  to="https://www.youtube.com/@beautifulresponsiveweb"
                  target="_blank"
                >
                  <div className="Social_Media_icon">
                    <IconYoutube />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="Footer-bottom">
            <div className="">
              <p>Beautiful Responsive Web © Copyright {getCurrentYear()}</p>
            </div>

            <div>
              <p>Privacy Policy | Terms of use</p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Footer;

import { motion } from "framer-motion";
import * as React from "react";


const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 4,
      ease: "easeInOut",
    },
  },
};

function IconBxPhoneCall(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      initial={{
        scale: 0,
        opacity: 0,
      }}
      animate={{
        scale: 1,
        opacity: 1,
      }}
      {...props}
    >
      <motion.path
        initial={{
          pathLength: 0,
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          pathLength: 1,
        }}
        transition={{
          delay: 0.4,
          duration: 0.6,
        }}
        d="M16.57 22a2 2 0 001.43-.59l2.71-2.71a1 1 0 000-1.41l-4-4a1 1 0 00-1.41 0l-1.6 1.59a7.55 7.55 0 01-3-1.59 7.62 7.62 0 01-1.59-3l1.59-1.6a1 1 0 000-1.41l-4-4a1 1 0 00-1.41 0L2.59 6A2 2 0 002 7.43 15.28 15.28 0 006.3 17.7 15.28 15.28 0 0016.57 22zM6 5.41L8.59 8 7.3 9.29a1 1 0 00-.3.91 10.12 10.12 0 002.3 4.5 10.08 10.08 0 004.5 2.3 1 1 0 00.91-.27L16 15.41 18.59 18l-2 2a13.28 13.28 0 01-8.87-3.71A13.28 13.28 0 014 7.41zM20 11h2a8.81 8.81 0 00-9-9v2a6.77 6.77 0 017 7z"
      />
      <motion.path
        initial={{
          pathLength: 0,
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          pathLength: 1,
        }}
        transition={{
          delay: 0.4,
          duration: 0.6,
        }}
        d="M13 8c2.1 0 3 .9 3 3h2c0-3.22-1.78-5-5-5z"
      />
    </svg>
  );
}

export default IconBxPhoneCall;

import React from 'react'
import './LogoDesign.css'
import { LogoData } from '../../component/Data/Data'

import { motion } from 'framer-motion'
import LogoCard from '../../component/card/Card'

// const containerVariants = {
//   hidden: {
//     opacity: 0,
//     x: '100vw'
//   },

//   visible: {
//     opacity: 1,
//     x: 0,
//     transition: {
//       type: 'spring',
//       delay: 1,
//       duration: 0.5
//     }
//   },
//   exit: {
//     x: '-100vw',
//     transition: { ease: 'easeInOut' }
//   }
// }

const LogoDesign = () => {

  const dataComp = LogoData.map((data) => {
    return(
    <LogoCard 
    key={data.id}
    title={data.title}
    src={data.src}
    desc={data.desc}
    />
    )
  })

  return (
    <motion.div 
    // variants={containerVariants} 
    // animate='visible' 
    // exit='exit'
    >
      <div className='LgooDesign'>
         {dataComp}
      </div>
    
    </motion.div>
  )
}

export default LogoDesign;

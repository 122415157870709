import React from 'react';
import './HowItWorks.css'

import ReseAnalytic from '../../images/researcana.png'
import DesignProty from '../../images/pprototype.png'
import pixelper from '../../images/pixelpeux.png'
import DevelopCod from '../../images/devproces.png'
import { motion } from 'framer-motion';

const HowItWorks = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      className="HowItWorks"
    >
      <div className="HowItWorks-Wrapper">
        <h1>That’s how we do it.</h1>
        <div className="HowItWorks-Content">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="HowItWorks-Process"
          >
            <div className="HowItWorks-Process-img">
              <img src={ReseAnalytic} alt="" />
            </div>

            <div className="HowItWorks-Process-Content">
              <h4>Research & Analytic</h4>
              <p>
                Understand, study & predict consumer behavior before coding.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="HowItWorks-Process"
          >
            <div className="HowItWorks-Process-img">
              <img src={DesignProty} alt="" />
            </div>

            <div className="HowItWorks-Process-Content">
              <h4>Prototyping Design</h4>
              <p>
                Here we build a sample product for our client to interact with
                before moving forward.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="HowItWorks-Process"
          >
            <div className="HowItWorks-Process-img">
              <img src={pixelper} alt="" />
            </div>

            <div className="HowItWorks-Process-Content">
              <h4>Pixel Perfect UI Design</h4>
              <p>
                User interface design is the look and feel of the application
                from the users prospective.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="HowItWorks-Process"
          >
            <div className="HowItWorks-Process-img">
              <img src={DevelopCod} alt="" />
            </div>

            <div className="HowItWorks-Process-Content">
              <h4>Development (coding)</h4>
              <p>
                This is the final stage here we impliment all the process
                through coing and deployment.
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}

export default HowItWorks

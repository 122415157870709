import * as React from "react";

function IconYoutube(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M14.792 10.775l-3.668-2.112A1.417 1.417 0 009 9.89v4.222c-.003.506.267.974.706 1.224.216.126.461.193.711.193.249 0 .493-.067.708-.191l3.667-2.112a1.413 1.413 0 000-2.45zm-.5 1.582l-3.666 2.113a.414.414 0 01-.419 0A.408.408 0 0110 14.11V9.89a.408.408 0 01.207-.359.402.402 0 01.418 0l3.667 2.113a.41.41 0 010 .714zM19 4H5a3.003 3.003 0 00-3 3v10a3.003 3.003 0 003 3h14a3.003 3.003 0 003-3V7a3.003 3.003 0 00-3-3zm2 13a2.003 2.003 0 01-2 2H5a2.003 2.003 0 01-2-2V7a2.003 2.003 0 012-2h14a2.003 2.003 0 012 2v10z" />
    </svg>
  );
}

export default IconYoutube;

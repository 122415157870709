import React from "react";
import "./WhyChooseUs.css";
import { motion } from "framer-motion";
import whyUsCheckImg from "../../images/Subtração 8.png";

const WhyChooseUs = () => {
  return (
    <motion.div className="WhyChooseUs">
      <div className="WhyChooseUs-Wrapper">
        <div className="WhyChooseUs-Content">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="WhyChooseUs-Content-Heading"
          >
            <h1>Why Choose Us</h1>
            <p>
              At Beautiful Responsive Web, we blend creativity with technical
              expertise, delivering innovative web solutions tailored to your
              unique needs. Choose us for a partnership that prioritizes
              quality, reliability, and a commitment to turning your digital
              vision into reality.
            </p>
          </motion.div>
          <div className="WhyChooseUs-Content-Main">
            <div className="WhyChooseUs-Main-Wrapper">
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                className="WhyChooseUs-Main"
              >
                <div className="WhyUsImg">
                  <img src={whyUsCheckImg} alt="" />
                </div>
                <div className="WhyChooseUs-Bottom">
                  <h4>Modern Technology</h4>
                  <p>
                    Using best practices and up-to-date technology, we build
                    customized websites that are not only clean and modern but
                    also mobile-responsive.
                  </p>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                className="WhyChooseUs-Main"
              >
                <div className="WhyUsImg">
                  <img src={whyUsCheckImg} alt="" />
                </div>
                <div className="WhyChooseUs-Bottom">
                  <h4>Huge Digital Experience</h4>
                  <p>
                    Using best practices and up-to-date technology, we build
                    customized websites that are not only clean and modern but
                    also mobile-responsive.
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default WhyChooseUs;

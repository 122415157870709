import React from "react";
import "./About.css";
import {
  TiSocialLinkedinCircular,
  TiSocialFacebookCircular,
  TiSocialInstagramCircular,
  TiSocialYoutubeCircular,
} from "react-icons/ti";
import { Link } from "react-router-dom";
import { FormTitle } from "../../component/FormTitle/FomTitle";
import { motion } from "framer-motion";
import AboutBook from "../../images/bookHistory.png";
import AboutTarget from "../../images/Target.png";
import AboutIdiom from "../../images/Aboutidiom.png";
import AboutM from "../../images/Aboutme.png";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      delay: 0.5,
    },
  },
  exit: {
    x: "-100vw",
    transition: { ease: "easeInOut" },
  },
};

const About = () => {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="About"
    >
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        className="About-Wrapper"
      >
        <div className="About-Content-Wrapper">
          <div className="About-Content-Heading">
            <FormTitle className="sign-in-form__title" text="About Us" />
            <p>
              Welcome to Beautiful Responsive Web, where innovation meets
              community empowerment. We are a dedicated development company
              committed to providing tailored solutions that enhance the online
              presence of small businesses and contribute to the growth and
              vibrancy of our community. Our mission is to empower small
              businesses and entrepreneurs by bridging the digital divide. We
              believe that every local venture has the potential for
              extraordinary success when equipped with the right tools and a
              strong online presence. Let's grow together and make a lasting
              impact on our community.
            </p>
          </div>
          <div className="About-Main-Content-wrapper">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              className="About-Main-Content"
            >
              <div className="About-Content-img">
                <img src={AboutBook} alt="Book" />
              </div>
              <div className="About-Content">
                <h4>Our History</h4>
                <p>
                  We are a consultancy firm offering diverse experience in
                  designing, developing, and maintaining scalable and compatible
                  technologies across multiple platforms, browsers, and devices.
                </p>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              className="About-Main-Content"
            >
              <div className="About-Content-img">
                <img src={AboutTarget} alt="Target" />
              </div>
              <div className="About-Content">
                <h4>Our Approach</h4>
                <p>
                  Adept at understanding the requirements of our clientele, we
                  design and deliver projects according to deadlines while
                  maintaining quality and code integrity to turn a concept into
                  a 'beautifully' finished project to accommodate current and
                  future needs.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          className="About-Developer"
        >
          <div className="About-Dev-Content">
            <h1>Meet our Talented Engineer</h1>
            <div className="About-Dav-Content-Main">
              <div className="About-Developer-Maint-Content">
                <h4>Bojurie Rogers-Wright</h4>
                <div className="About-Dev-main">
                  <div className="ABout-Img">
                    <img className="absol" src={AboutIdiom} alt="Developer" />
                  </div>

                  <div className="About-Dev-Posi">
                    <p>Position</p>
                    <h5>UI/UX Designer</h5>
                  </div>
                </div>
                <div className="About-Dev-main">
                  <div
                    className="ABout-Img bgGreen"
                    style={{ backgroundColor: "green" }}
                  >
                    <img className="absol" src={AboutIdiom} alt="Developer" />
                  </div>

                  <div className="About-Dev-Posi">
                    <p>Position</p>
                    <h5>Developer</h5>
                  </div>
                </div>
                <div className="Social-Media">
                  <Link to="">
                    <div className="Social_Media_About-icon">
                      <TiSocialLinkedinCircular /> <p></p>
                    </div>
                  </Link>

                  <Link to="">
                    <div className="Social_Media_About-icon">
                      <TiSocialFacebookCircular />
                      <p></p>
                    </div>
                  </Link>

                  <Link to="">
                    <div className="Social_Media_About-icon">
                      <TiSocialInstagramCircular />
                      <p></p>
                    </div>
                  </Link>
                  <Link to="">
                    <div className="Social_Media_About-icon">
                      <TiSocialYoutubeCircular />
                      <p></p>
                    </div>
                  </Link>
                </div>
              </div>
              <div>
                <div className="About-Bottom-Img">
                  <img src={AboutM} alt="About Me" />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default About;

import ProKaneli from "../../images/Kanel.png";
import AdvantFamLogo from "../../images/Advants.png";
import TheWrightLog from "../../images/TheWrightLogistic.png";
import WrightLogMov from "../../images/wrightlogistic.mov";
import ZilliPizzMov from "../../images/ZilliPizz.mov";
import FamilyAdvMov from "../../images/FamilyAdv.mov";
import PizzaMakerMov from "../../images/pizza-maker.mov";
import PhotoGraphMov from "../../images/photographMov.mov";
import PhotoGraphLogo from "../../images/FLASH.png";
import Qualitea from "../../images/quality.png";
import QualiteaMov from "../../images/qualitea.mov";
import { ImArrowLeft } from "react-icons/im";

const WrightLogistic = "https://the-wright-logistic-client.onrender.com";
const QualitTeaLink = "https://qualitea.onrender.com";
const PizzaLink = "https://pizza-maker-th98.onrender.com";
const ZilloPizzaLink = "https://zpizza.onrender.com";
const FamilyAdvLink = "https://family-planing.onrender.com";
const PhotoGraLink = "https://photography-profile.onrender.com";


export const HomeProjectData = [
  {
    id: 1,
    projectLogo: TheWrightLog,
    projectVideo: WrightLogMov,
    link: WrightLogistic,
    clientName: "The Wright Logistics",
    desc: "",
    projectTitle: "Logistics Website",
    projectAction: <ImArrowLeft />,
    Developer: "",
    fundsRequired: "1k USD",
    fundStatus: 1,
    metaList: {
      frontend: " ReactJs",
      backend: " NodeJs ",
      soft_cap: "Reached",
      employee_count: 3,
    },
  },
  {
    id: 2,
    projectLogo: Qualitea,
    projectVideo: QualiteaMov,
    link: QualitTeaLink,
    clientName: "QualiTea",
    desc: "",
    projectTitle: "Health & Wellness",
    projectAction: <ImArrowLeft />,
    Developer: "",
    fundsRequired: "1k USD",
    fundStatus: 1,
    metaList: {
      frontend: " ReactJs",
      backend: " NodeJs ",
      soft_cap: "Reached",
      employee_count: 3,
    },
  },
  {
    id: 3,
    projectLogo: ProKaneli,
    projectVideo: PizzaMakerMov,
    link: PizzaLink,
    clientName: "Make A Pizza",
    desc: "",
    projectTitle: "Food Service",
    projectAction: <ImArrowLeft />,
    Developer: "",
    fundsRequired: "1k USD",
    fundStatus: 1,
    metaList: {
      frontend: " ReactJs",
      backend: " NodeJs ",
      soft_cap: "Reached",
      employee_count: 3,
    },
  },
];


export const ProjectData = [
  {
    id: 1,
    projectLogo: TheWrightLog,
    projectVideo: WrightLogMov,
    link: WrightLogistic,
    clientName: "The Wright Logistics",
    desc: "",
    projectTitle: "Logistics Website",
    projectAction: <ImArrowLeft />,
    Developer: "",
    fundsRequired: "1k USD",
    fundStatus: 1,
    metaList: {
      frontend: " ReactJs",
      backend: " NodeJs ",
      soft_cap: "Reached",
      employee_count: 3,
    },
  },
  {
    id: 2,
    projectLogo: Qualitea,
    projectVideo: QualiteaMov,
    link: QualitTeaLink,
    clientName: "QualiTea",
    desc: "",
    projectTitle: "Health & Wellness",
    projectAction: <ImArrowLeft />,
    Developer: "",
    fundsRequired: "1k USD",
    fundStatus: 1,
    metaList: {
      frontend: " ReactJs",
      backend: " NodeJs ",
      soft_cap: "Reached",
      employee_count: 3,
    },
  },
  {
    id: 3,
    projectLogo: ProKaneli,
    projectVideo: PizzaMakerMov,
    link: PizzaLink,
    clientName: "Make A Pizza",
    desc: "",
    projectTitle: "Food Service",
    projectAction: <ImArrowLeft />,
    Developer: "",
    fundsRequired: "1k USD",
    fundStatus: 1,
    metaList: {
      frontend: " ReactJs",
      backend: " NodeJs ",
      soft_cap: "Reached",
      employee_count: 3,
    },
  },
  {
    id: 4,
    projectLogo: TheWrightLog,
    projectVideo: ZilliPizzMov,
    link: ZilloPizzaLink,
    clientName: "Zillicious Pizza",
    desc: "",
    projectTitle: "Food Services",
    projectAction: <ImArrowLeft />,
    Developer: "",
    fundsRequired: "",
    fundStatus: 1,
    metaList: {
      frontend: " ReactJs",
      backend: " NodeJs ",
      soft_cap: "Reached",
      employee_count: 3,
    },
  },
  {
    id: 5,
    projectLogo: TheWrightLog,
    projectVideo: FamilyAdvMov,
    link: FamilyAdvLink,
    clientName: "QualiTea",
    desc: "",
    projectTitle: "Health & Wellness",
    projectAction: <ImArrowLeft />,
    Developer: "",
    fundsRequired: "1k USD",
    fundStatus: 1,
    metaList: {
      frontend: " ReactJs",
      backend: " NodeJs ",
      soft_cap: "Reached",
      employee_count: 3,
    },
  },
  {
    id: 6,
    projectLogo: TheWrightLog,
    projectVideo: PhotoGraphMov,
    link: PhotoGraLink,
    clientName: "Make A Pizza",
    desc: "",
    projectTitle: "Food Service",
    projectAction: <ImArrowLeft />,
    Developer: "",
    fundsRequired: "1k USD",
    fundStatus: 1,
    metaList: {
      frontend: " ReactJs",
      backend: " NodeJs ",
      soft_cap: "Reached",
      // employee_count: 3,
    },
  },
  // {
  //   id: 7,
  //   projectLogo: TheWrightLog,
  //   projectVideo: WrightLogMov,
  //   link: WrightLogistic,
  //   clientName: "The Wright Logistics",
  //   desc: "",
  //   projectTitle: "Logistics Website",
  //   projectAction: <ImArrowLeft />,
  //   Developer: "",
  //   fundsRequired: "1k USD",
  //   fundStatus: 1,
  //   metaList: {
  //     frontend: " ReactJs",
  //     backend: " NodeJs ",
  //     soft_cap: "Reached",
  //     employee_count: 3,
  //   },
  // },
  // {
  //   id: 8,
  //   projectLogo: Qualitea,
  //   projectVideo: QualiteaMov,
  //   link: WrightLogistic,
  //   clientName: "QualiTea",
  //   desc: "",
  //   projectTitle: "Health & Wellness",
  //   projectAction: <ImArrowLeft />,
  //   Developer: "",
  //   fundsRequired: "1k USD",
  //   fundStatus: 1,
  //   metaList: {
  //     frontend: " ReactJs",
  //     backend: " NodeJs ",
  //     soft_cap: "Reached",
  //     employee_count: 3,
  //   },
  // },
  // {
  //   id: 9,
  //   projectLogo: ProKaneli,
  //   projectVideo: PizzaMakerMov,
  //   link: WrightLogistic,
  //   clientName: "Make A Pizza",
  //   desc: "",
  //   projectTitle: "Food Service",
  //   projectAction: <ImArrowLeft />,
  //   Developer: "",
  //   fundsRequired: "1k USD",
  //   fundStatus: 1,
  //   metaList: {
  //     frontend: " ReactJs",
  //     backend: " NodeJs ",
  //     soft_cap: "Reached",
  //     employee_count: 3,
  //   },
  // },
  // {
  //   id: 10,
  //   projectLogo: ProKaneli,
  //   projectVideo: PizzaMakerMov,
  //   link: WrightLogistic,
  //   clientName: "Make A Pizza",
  //   desc: "",
  //   projectTitle: "Food Service",
  //   projectAction: <ImArrowLeft />,
  //   Developer: "",
  //   fundsRequired: "1k USD",
  //   fundStatus: 1,
  //   metaList: {
  //     frontend: " ReactJs",
  //     backend: " NodeJs ",
  //     soft_cap: "Reached",
  //     employee_count: 3,
  //   },
  // },
];

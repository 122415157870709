import { motion } from "framer-motion";
import React from "react";
import Form from "../../component/ContactForm/Form";
import ContactLinks from "../../component/ContactLinks/ContactLinks";
import { FormTitle } from "../../component/FormTitle/FomTitle";
import ContactImg from "../../images/ContactImg.png";
import "./Contact.css";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      type: "spring",
      delay: 0.5,
    },
  },
  exit: {
    x: "-100vw",
    transition: { ease: "easeInOut" },
  },
};

const Contact = () => {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="Contact"
    >
      <div className="Contact-Wrapper">
        <div className="Contact-Header">
          <FormTitle
            className="sign-in-form__title"
            text="Get in Touch With Us"
          />
        </div>
        <div className="contact-parag">
          <p>
            Have a question but aren’t sure who to contact? Fill in the form to
            get in touch, and a member of our team will reach out to you at the
            earliest.
          </p>
        </div>

        <div className="Contact-Form-Wrapper">
          <motion.div
            className="Contact-Form"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", stiffness: 120 }}
          >
            <div className="Contact-Form-Content">
              <div className="FormContact">
                <Form />
              </div>

              <motion.div
                className="Contact-Img"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              >
                <div className="Contact-Img-Div">
                  <img src={ContactImg} alt="" />
                </div>
              </motion.div>
            </div>
          </motion.div>
        </div>
        <ContactLinks />
      </div>
    </motion.div>
  );
};

export default Contact;

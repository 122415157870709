import FirstDesign from "../../images/CheapGas.png";
import SecondDesign from "../../images/quality.png";
import ThirdDesign from "../../images/SaloneBusiness.png";
import ForthDesign from "../../images/SunChildren.png";
import FifthDesign from "../../images/TheWrightLogistic.png";
import SeventhDesign from "../../images/BeautifulRWeb.png";
import SixthDesign from "../../images/quality.png";
import EightDesign from "../../images/AfricanInAmerica.png";
import ninthDesign from "../../images/Akofena.png";
import tenthDesign from "../../images/AthleticP.png";
import elevenDesign from "../../images/funished.png";



export const LogoData = [
  {
    id: 1,
    title: "",
    desc: "",
    src: FirstDesign,
  },
  {
    id: 2,
    title: "",
    desc: "",
    src: SecondDesign,
  },
  {
    id: 3,
    title: "",
    desc: "",
    src: ThirdDesign,
  },
  {
    id: 4,
    title: "",
    desc: "",
    src: ForthDesign,
  },
  {
    id: 5,
    title: "",
    desc: "",
    src: FifthDesign,
  },
  {
    id: 6,
    title: "",
    desc: "",
    src: SixthDesign,
  },
  {
    id: 7,
    title: "",
    desc: "",
    src: SeventhDesign,
  },
  {
    id: 8,
    title: "",
    desc: "",
    src: EightDesign,
  },
  {
    id: 9,
    title: "",
    desc: "",
    src: ninthDesign,
  },
  {
    id: 10,
    title: "",
    desc: "",
    src: tenthDesign,
  },
  {
    id: 11,
    title: "",
    desc: "",
    src: elevenDesign,
  },
];

export const WebDesignData = [
  {
    id: 1,
    title: "",
    desc: "",
    src: "",
  },
  {
    id: 2,
    title: "",
    desc: "",
    src: "",
  },
  {
    id: 3,
    title: "",
    desc: "",
    src: "",
  },
  {
    id: 4,
    title: "",
    desc: "",
    src: "",
  },
  {
    id: 5,
    title: "",
    desc: "",
    src: "",
  },
  {
    id: 6,
    title: "",
    desc: "",
    src: "",
  },
  {
    id: 7,
    title: "",
    desc: "",
    src: "",
  },
  {
    id: 8,
    title: "",
    desc: "",
    src: "",
  },
  {
    id: 9,
    title: "",
    desc: "",
    src: "",
  },
  {
    id: 10,
    title: "",
    desc: "",
    src: "",
  },
];

import { motion } from "framer-motion";
import React from "react";
import Hero from "../../component/Hero/Hero";
import Projects from "../../component/HomeProjects/Projects";
import HowItWorks from "../../component/HowitWorks/HowItWorks";
import Designs from "../../component/LogoDesigns/Designs";
import Review from "../../component/Review/Review";
import WhatWeDo from "../../component/WhatWeDo/WhatWeDo";
import "./Home.css";

const containerVariants = {
  hidden: {
    opacity: 0,
    // x: '100vw'
  },

  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      delay: 1,
      duration: 0.5,
    },
  },
  exit: {
    x: "-100vw",
    transition: { ease: "easeInOut" },
  },
};

function Home() {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="Home"
    >
      <Hero />
      <Designs />
      <WhatWeDo />
      <Review />
      <Projects />
      <HowItWorks />
    </motion.div>
  );
}

export default Home;

import React from "react";
import './Navigarion.css'
import Navlinks from "../Navlinks/Navlinks";

const Navigation = () => {
  return (
    <nav className="Navigation">
      <Navlinks />
    </nav>
  );
};

export default Navigation;

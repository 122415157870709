// icon:bx-location-plus | Boxicons https://boxicons.com/ | Atisa
import * as React from "react";

function IconBxLocationPlus(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M11.42 21.815a1.004 1.004 0 001.16 0C12.884 21.598 20.029 16.44 20 10c0-4.411-3.589-8-8-8S4 5.589 4 9.996c-.029 6.444 7.116 11.602 7.42 11.819zM12 4c3.309 0 6 2.691 6 6.004.021 4.438-4.388 8.423-6 9.731-1.611-1.308-6.021-5.293-6-9.735 0-3.309 2.691-6 6-6z" />
      <path d="M11 14h2v-3h3V9h-3V6h-2v3H8v2h3z" />
    </svg>
  );
}

export default IconBxLocationPlus;

import React from "react";
import "./ContactLinks.css";
import PhoneImg from "../../images/phone.png";
import MailImg from "../../images/Mail.png";
import LocatImg from "../../images/Location.png";

const ContactLinks = () => {
  return (
    <div className="ContactLinks">
      <div className="ContactLinks-Wrapper">
        <div className="ContactLinks-Wrapper-Content">
          <div className="ContactLinks-Content">
            <div className="Phone-img">
              <img src={PhoneImg} alt="" />
            </div>
            <div className="Phone-Link">
              <h4>Call Us</h4>
              <p>+1 949-919-4017</p>
            </div>
          </div>
          <div className="ContactLinks-Content">
            <div className="Phone-img">
              <img src={MailImg} alt="" />
            </div>
            <div className="Phone-Link">
              <h4>Message Us</h4>
              <p>beautifulresponsiveweb@gmail.com</p>
            </div>
          </div>
          <div className="ContactLinks-Content">
            <div className="Phone-img">
              <img src={LocatImg} alt="" />
            </div>
            <div className="Phone-Link">
              <h4>Visit Us</h4>
              <p>Orange County, California</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactLinks;

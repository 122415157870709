import React from "react";

import "./HeaderNavbar.css";
import Logo from "../../images/Logo1.svg";
import LogoText from "../../images/LogoText.png";
import MobileNavigation from "../MobileNavigation/MobileNavigation";
import Navigation from "../Navigation/Navigation";
import { Link } from "react-router-dom";
import {motion} from 'framer-motion'

const HeaderNavbar = () => {
  return (
    <div className="Header">
      <div className="container">
        <nav className="Navbar">
          <div className="logo">
            <Link to="/" className="navbar-logo">
              <div className="Logo">
                <motion.img
                  className="img-Logo"
                  animate={{
                    rotate: 360,
                  }}
                  transition={{
                    ease: "linear",
                    duration: 4,
                    repeat: Infinity,
                  }}
                  src={Logo}
                  alt=""
                />
                <img className="text-Logo" src={LogoText} alt="" />
              </div>
            </Link>
          </div>
          <Navigation />
          <MobileNavigation />
        </nav>
      </div>
    </div>
  );
};

export default HeaderNavbar;

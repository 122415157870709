import React, { useState } from "react";
import "./Form.css";
import axios from "axios";
import { motion } from "framer-motion";

const buttonVariants = {
  hover: {
    scale: 1,
    textShadow: "0px 0px 8px rgb(255,255,255,)",
    boxShadow: "0px 0px 8px rgb(255,255,255,)",
  },
};

const Form = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    phoneNumber: "",
    lastName: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const postMessage = (e) => {
    e.preventDefault();
    axios
      .post("http://localhost:5005/contact", formData)
      .then((res) => console.log("Posting Message", res))
      .catch((err) => console.log(err));
  };

  return (
    <form className="Form">
      <div className="Form-input">
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          placeholder="First name..."
        />
        <input
          type="text"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          placeholder="Phone Number..."
        />
      </div>
      <div className="Form-input">
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          placeholder="Last name..."
        />
        <input
          type="text"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          placeholder="Subject..."
        />
      </div>
      <div className="Form-textarea">
        <div className="Textarea">
          <textarea
            type="text"
            style={{ padding: "10px" }}
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Your message..."
          />
        </div>
      </div>
      <div className="Contact-Button">
        <motion.button
          variants={buttonVariants}
          whileHover="hover"
          className="news-Btn"
          type="button"
          onClick={postMessage}
        >
          Submit
        </motion.button>
      </div>
    </form>
  );
};

export default Form;

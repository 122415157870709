import { motion } from "framer-motion";
import React from "react";
import ProjectCard from "../ProjectCards/ProjectCard";
import { HomeProjectData } from "../ProjectCards/ProjectData";
import "./Projects.css";
import { Link } from "react-router-dom";

const Projects = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      className="Project"
    >
      <div className="Project-Wrapper">
        <div className="Project-Header-Wrapper1">
          <h1 className="HomeProject-header">Take a look at our projects</h1>
          <p className="HomeProject-Pa">
            Explore Our Latest Software Solutions for Your Business Needs
          </p>
        </div>
        <div className="ProjectsContainer">
          {HomeProjectData?.map((item) => {
            return <ProjectCard data={item} />;
          })}
        </div>
        <div className="More-btn">
          <button className="moreProjectBtn">
            <Link to="/work">More</Link>
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default Projects;

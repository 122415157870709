import { motion } from "framer-motion";
import React from "react";
import "./Review.css";

const reviews = [
  {
    content:
      "I was referred by a friend to check out this company and honestly, I was skeptical at first due to the complexity of my project. After speaking to them, I was reassured by the great communication and transparency. I would definitely come back again.",
    profile: {
      name: "Sasha Katel",
      role: "Client",
      image:
        "https://images.unsplash.com/photo-1554727242-741c14fa561c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80",
    },
  },
  {
    content:
      "I found this company on Instagram and decided to contact them to see if they will be able to assist me in creating a modern website design for my new business. I was very pleased with my experience with them in making the process easy and stress-free. Thank you.",
    profile: {
      name: "Michelle Akadu",
      role: "Client",
      image:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    },
  },
  {
    content:
      "I was in search for a good designer who is also experienced in development when I stumbled upon this company. They made it easy for me to communicate exactly what I wanted and kept me updated every step of the process. Thank you.",
    profile: {
      name: "Usman Yusef",
      role: "Client",
      image:
        "https://images.unsplash.com/photo-1608791952180-79294109d843?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80",
    },
  },
];

const Review = () => {
  return (
    <div className="Review">
      <div className="Review-Wrapper">
        <div className="Review-Header-Section">
          <h1>What Customers Say About</h1>
          <div className="Review-Content-Wrapper">
            {reviews.map((review, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                whileHover={{ scale: 1.02 }}
                className="Review-Content"
              >
                <div className="Review-Content-Maint">
                  <p>
                    <span>"</span> <br /> {review.content}
                  </p>{" "}
                  <br />
                  <hr />
                  <div className="Review-Profile">
                    <div className="Review-Profile-img">
                      <img
                        src={review.profile.image}
                        alt={review.profile.name}
                      />
                    </div>

                    <div className="Review-Profile-Content">
                      <h3>{review.profile.name}</h3>
                      <p>{review.profile.role}</p>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;

import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "../HeaderNavbar/HeaderNavbar.css";

const Navlinks = (props) => {
  const animateFrom = { opacity: 0, y: -40 };
  const animateTo = { opacity: 1, y: 0 };
  return (
    <nav className="nav">
      <ul className="Navbar-links">
        <motion.li
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.05 }}
          onClick={() => props.isMobile && props.closeMobileMenu()}
        >
          <Link to="/">Home</Link>
        </motion.li>
        <motion.li
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.1 }}
          onClick={() => props.isMobile && props.closeMobileMenu()}
        >
          <Link to="/work">Dev Projects</Link>
        </motion.li>
        <motion.li
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.2 }}
          onClick={() => props.isMobile && props.closeMobileMenu()}
        >
          <li>
            <Link to="/logodesign">Logo Designs</Link>
          </li>
        </motion.li>
        <motion.li
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.3 }}
          onClick={() => props.isMobile && props.closeMobileMenu()}
        >
          <Link to="/services">Services</Link>
        </motion.li>
        <motion.li
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.4 }}
          onClick={() => props.isMobile && props.closeMobileMenu()}
        >
          <Link to="/about">About Us</Link>
        </motion.li>

        {/* <motion.li
                  initial={animateFrom} 
                  animate={animateTo}
                  transition={{delay: 0.50}}
                  onClick={() => props.isMobile && props.closeMobileMenu()}>
                <li><Link to='/login'>LogIn</Link></li>
              </motion.li> 

              <motion.li 
                  initial={animateFrom} 
                  animate={animateTo}
                  transition={{delay: 0.60}}
                  onClick={() => props.isMobile && props.closeMobileMenu()}>
                  <li><Link to='/register'>Sign-Up</Link></li>
              </motion.li> */}

        <motion.li
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.7 }}
          onClick={() => props.isMobile && props.closeMobileMenu()}
        >
          <div className="btn contact-link">
            <Link to="/contact">Contact</Link>
          </div>
        </motion.li>
      </ul>
    </nav>
  );
};

export default Navlinks;

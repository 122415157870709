import styled from 'styled-components';
import {motion} from 'framer-motion';


export const Card = styled(motion.div)`

`;

export const CardMedia = styled(motion.div)`
height: auto;
position: relative;
margin: 15px;
border-radius: 20px;
overflow: hidden;

video{
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: all .4s ease;
}
`
export const Featured = styled.div`
display: grid;
grid-template-column: 1fr 4fr;
padding: 0 10px;
position: absolute;
botttom: 25px;
left: 50%;
transform: translateX(-50%);
diplay: flex;
align-items: center;
justify-content: center;
font-size: 14px;
line-height: 23px;
text-transform: uppercase;
border-radius: 13px;
background-color: #343444
height: 36px;
width: 151px;
}
`

export const FeatureTitle = styled.span`
text-align: center;
letter-spacing: 1px;
`

export const StatusRibbon= styled.div`
background: ${({fundStatus}) => fundStatus === 1 ? "#664858" : "#df4949"};
border-radius: 13px;
padding: 3px 10px;
position: absolute;
right: 10px;
top: 10px;
`

export const Status = styled.span`
  font-size: 14px;
`

export const CardBody = styled.div`
  padding: 4px 20px 20px 20px;
`
export const CardTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  letter-spacing: 1px;
  // margin-bottom: 12px;
  text-transform: capitalize;
`;

export const CardLink = styled.a`
color: inherit;
`
export const MetaInfo = styled.div`
height: auto;
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 4px;

`
export const Developer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
text-align: left;
width: 100%;
`
export const Avatar = styled.div`
width: 120px;
// height: 50px;
// border-radius: 30px;
overflow: hidden;
// margin: 0px 0px 10px 0px;
display: flex;
align-items: center;

img {
  object-fit: fit;
  width: 100%;
  height: 100%;

}
`
export const Info = styled.div`
margin: 0 auto;

`
export const PriceInfo = styled.div`
max-width: 140px;
`
export const Price = styled.h5`
display: flex;
margin-top: 6px;
`
export const PriceText = styled.div`
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
margin-right: .3rem
`
export const TagsCa = styled(motion.div)`
display: flex;
align-items: center;
margin-top: 5px;
justify-content: space-between;
`
export const Tags = styled(motion.div)`
width: fit-content;
padding: 0 7px;
font-size: 12px;
border-radius: 6px;
height: 24px;
line-height: 24px;
text-align: center;
font-weight: bold;
background-color: #5142fc;
letter-spacing: .1rem;
text-transform: uppercase;
`
export const CtaGarp = styled.div`
display: flex;
`
export const CtaLink = styled.a`
width: fit-content;
padding: 0 6px;
height: 24px;
line-height: 24px;
text-align: center;
font-weight: bold;
font-size: 12px;
border-radius: 6px;
background-color: #474757;
letter-spacing: .1rem;
text-transform: uppercase;
margin-left: 4px;
transition: background-color .25s ease;
cursor: pointer;
z-index: 1;
text-decoration: none;
color: #fff;
`
export const MetaList = styled(motion.div)`

`
export const MetaListItem = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 14px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #232332;
  }
  &>:last-child{
    font-size: 16px;
    font-weight: 600;
    text-align: right;
  }


`;
import React from "react";
import "./Card.css";

function Card(props) {
  return (
    <div className="LogoCard">
      <div className="Card-Image">
        <img src={props.src} alt="" />
      </div>

      <h3>{props.title}</h3>
      <p>{props.desc}</p>
    </div>
  );
}

export default Card;

import React from "react";
import "./Hero.css";
import "../Buttons/NonActiveButton.css";
import herologo from "../../images/Herobglogo.png";
import img from "../../images/União701.png";
import { Link } from "react-router-dom";
import { animate, motion } from "framer-motion";

const heroLeftVariants = {
  hidden: {
    opacity: 0,
    x: "100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      mass: 0.4,
      damping: 8,
      when: "beforeChildren",
    },
  },
};
const buttonVariants = {
  visible: {
    opacity: 1,
  },
  transition: {
    hover: {
      transition: {
        duration: 2,
        yoyo: 5,
      },
    },
  },
};

const childVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 3,
      duration: 3,
    },
  },
};

const textAnimate = {
  hidden: { opacity: 0, x: -100 },
  animate: {
    x: 500,
    transition: {
      duration: "3",
      delay: "1",
    },
  },
};

const Hero = () => {
  return (
    <div className="Hero">
      <div className="Hero-Wrapper">
        <div className="Hero-Content">
          <motion.div
            variants={heroLeftVariants}
            initial="hidden"
            animate="visible"
            className="Hero-Left"
          >
            <div className="Hero-Left-Wrapper">
              <div className="Hero-Header">
                <motion.h2 variants={textAnimate} animate="visible">
                  Let's Create Something
                </motion.h2>
              </div>
              <motion.div variants={heroLeftVariants} className="Hero-main">
                <p>
                  Beautiful Responsive Designs created from scratch with no
                  templete. All Customized to your specification.
                </p>
                <div className="Hero-buttons">
                  <Link to="/contact">
                    <motion.button
                      whileHover={{
                        scale: 1.1,
                        textShadow: "0px 0px 8px rgb(255,255,255,)",
                        boxShadow: "0px 0px 8px rgb(255,255,255,)",
                      }}
                      whileTap={{ scale: 0.9 }}
                      type="button"
                      variants={childVariants}
                      className="button"
                    >
                      Get Started
                    </motion.button>
                  </Link>

                  <Link to="/about">
                    <motion.button
                      whileHover={{
                        scale: 1.1,
                        textShadow: "0px 0px 8px rgb(255,255,255,)",
                        boxShadow: "0px 0px 8px rgb(255,255,255,)",
                      }}
                      whileTap={{ scale: 0.9 }}
                      type="button"
                      variants={childVariants}
                      className="nonActiveButton"
                    >
                      Case Studies
                    </motion.button>
                  </Link>
                </div>
              </motion.div>
            </div>
          </motion.div>
          <div className="Hero-right">
            <div className="Hero-right-img">
              <div className="HERO_IMG">
                <motion.img
                  initial={{ y: "-200vh" }}
                  animate={{ y: 0 }}
                  transition={{ delay: 1, duration: 3 }}
                  className="herologo"
                  src={herologo}
                  alt=""
                />
              </div>
            </div>
            <div className="hero-pers">
              <motion.img
                initial={{ y: "100vw" }}
                animate={{ y: 0 }}
                transition={{ delay: 1, duration: 3 }}
                src={img}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

import React from "react";
import "./Projects.css";
import { FormTitle } from "../../component/FormTitle/FomTitle";
import ProjectItem from "../../component/ProjectItem/ProjectItem";
import { motion } from "framer-motion";
import { ProjectData } from "../../component/ProjectCards/ProjectData";
import ProjectCard from "../../component/ProjectCards/ProjectCard";

// const containerVariants = {
//   hidden: {
//     opacity: 0,
//     // x: '100vw'
//   },
//   visible: {
//     opacity: 1,
//     x: 0,
//     transition: {
//       type: "spring",
//       delay: 0.5,
//     },
//   },
//   exit: {
//     x: "-100vw",
//     transition: { ease: "easeInOut" },
//   },
// };

function Projects() {
  return (
    <motion.div
      // variants={containerVariants}
      // initial="hidden"
      // animate="visible"
      // exit="exit"
      className="Projects"
    >
      <div className="Projects-Wrapper">
        <div className="Projects-Wrapper-Main">
          <div className="Projects-Heading">
            <FormTitle className="sign-in-form__title" text="Projects" />
            <p className="Project-parag">
              We are a Consultancy firm offering diverse experience designing,
              developing, and maintaining technologies both scalable and
              compatible across multiple platforms, browsers, and devices.
            </p>
          </div>
          <div className="Projects-Container">
            {ProjectData?.map((item) => {
              return <ProjectCard data={item} />;
            })}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Projects;

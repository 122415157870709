import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import WebDevLogo from "../../images/webdev.png";
import AppDev from "../../images/appdev.png";
import DeskDev from "../../images/desktdev.png";
import UxDev from "../../images/uxdes.png";
import GrapDev from "../../images/graphicdes.png";
import BranDev from "../../images/brandguid.png";
import NextbtnImg from "../../images/arrow.png";
import "./WhatWeDo.css";

const itemVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};

const WhatWeDo = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="WhatWeDo"
    >
      <div className="WhatWeDo-Wrapper">
        <div className="WhatWeDo-Wrapper-Top">
          <div className="WhatWeDo-Header">
            <h1>We Do Everything</h1>
            <p>
              We specialize in everything from making sure your business has a
              web presence by <br /> creating a new website or refactoring your
              current website to make it mobile responsive.
            </p>
          </div>

          <div className="WhatWeDo-Content">
            <div className="WhatWeDo-Content-Wrapper">
              {[
                {
                  color: "#222842",
                  logo: WebDevLogo,
                  link: "/webDev",
                  title: "Web Development",
                },
                {
                  color: "#0FC0CF",
                  logo: AppDev,
                  link: "/appdev",
                  title: "App Development",
                },
                {
                  color: "#1CC191",
                  logo: DeskDev,
                  link: "/desktopapplication",
                  title: "Desktop Application",
                },
                {
                  color: "#512888",
                  logo: UxDev,
                  link: "/uxdesign",
                  title: "UI/UX Design",
                },
                {
                  color: "#EF612B",
                  logo: GrapDev,
                  link: "/graphicdesigns",
                  title: "Graphics Designing",
                },
                {
                  color: "#425AC1",
                  logo: BranDev,
                  link: "/branding",
                  title: "Branding Guides",
                },
              ].map((item, index) => (
                <motion.div
                  key={index}
                  variants={itemVariants}
                  className="WhatWeDo-Content-Main"
                >
                  <div
                    className="WhatWeDo-Main"
                    style={{ backgroundColor: item.color }}
                  >
                    <Link to={item.link}>
                      <div className="WhatWeDo-Main-Content">
                        <h2>{item.title}</h2>
                        <img className="NextbtnImg" src={NextbtnImg} alt="" />
                      </div>
                      <div className="WhatWeDo-Logo">
                        <img className="WebDevLogo" src={item.logo} alt="" />
                      </div>
                    </Link>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default WhatWeDo;
